import React from "react"
import Layout from "../components/Layout"
import "../css/projects/pythonprojectcard.css"
import { Link } from "gatsby"
import Particle7 from "../components/Particle7"
import SEO from "../components/SEO"

const Pythonprojectcard = () => {
    return (
        <Layout>
            <SEO
                title="Python Projects"
                description="This Page is for Python Project Ocean."
            />
            <Particle7></Particle7>
            <h6>s</h6>
            <div className="programming-projectp">
                <h1>Neural Network and Deep Learning</h1>
            </div>

            <div className="favp noSelect">
                <h3>
                    Those projects having a{" "}
                    <span role="img" aria-label="Heart">
                        ❤️
                    </span>{" "}
                    on them... come under the Favourite category.{" "}
                </h3>
            </div>

            <section className="most-likedp noSelect">
                <h3 className="totalp">Total Projects :- 6</h3>
                <div className="rowp">
                    <div className="rotatep">
                        <div className="coffee-cardp">
                            <div className="card-headerp">
                                <h3 className="title-textp">
                                    Logistic Regression with a Neural Network Mindset
                                    <span role="img" aria-label="Heart">
                                        ❤️
                                    </span>
                                </h3>
                            </div>
                            <div className="dl-img1"></div>
                            <div className="backp">
                                <div className="back-contentp">
                                    <div className="layer"></div>
                                    <h4 className="textp">Checkout here...</h4>
                                    <div className="bttn-boxp">
                                        <a
                                            href="https://github.com/arpitsomani8/Neural-Nework-And-Deep-Learning-Models/tree/master/Logistic%20Regression%20with%20a%20Neural%20Network%20mindset"
                                            className="btnttp"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Github Code
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="rotatep">
                        <div className="coffee-cardp">
                            <div className="card-headerp">
                                <h3 className="title-textp">
                                    Planar data classification with one hidden layer
                                    <span role="img" aria-label="Heart">
                                        ❤️
                                    </span>
                                </h3>
                            </div>
                            <div className="dl-img2"></div>
                            <div className="backp">
                                <div className="back-contentp">
                                    <div className="layer"></div>
                                    <h4 className="textp">Checkout here...</h4>
                                    <div className="bttn-boxp">
                                        <a
                                            href="https://github.com/arpitsomani8/Neural-Nework-And-Deep-Learning-Models/tree/master/Planar%20data%20classification%20with%20one%20hidden%20layer"
                                            className="btnttp"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Github Code
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="rotatep">
                        <div className="coffee-cardp">
                            <div className="card-headerp">
                                <h3 className="title-textp">
                                    TensorFlow and Keras Basic Syntax
                                    <span role="img" aria-label="Heart">
                                        ❤️
                                    </span>
                                </h3>
                            </div>
                            <div className="dl-img3"></div>
                            <div className="backp">
                                <div className="back-contentp">
                                    <div className="layer"></div>
                                    <h4 className="textp">Checkout here...</h4>
                                    <div className="bttn-boxp">
                                        <a
                                            href="https://github.com/arpitsomani8/TensorFlow-And-Keras/tree/master/TensorFlow%20and%20Keras%20Basic%20Syntax"
                                            className="btnttp"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Github Code
                                        </a>


                            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="rotatep">
                        <div className="coffee-cardp">
                            <div className="card-headerp">
                                <h3 className="title-textp">
                                    TensorFlow and Keras Classification Model
                                    <span role="img" aria-label="Heart">
                                        ❤️
                                    </span>
                                </h3>
                            </div>
                            <div className="dl-img4"></div>
                            <div className="backp">
                                <div className="back-contentp">
                                    <div className="layer"></div>
                                    <h4 className="textp">Checkout here...</h4>
                                    <div className="bttn-boxp">
                                        <a
                                            href="https://github.com/arpitsomani8/TensorFlow-And-Keras/tree/master/TensorFlow%20and%20Keras%20Classification%20Model"
                                            className="btnttp"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Github Code
                                        </a>


                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="rotatep">
                        <div className="coffee-cardp">
                            <div className="card-headerp">
                                <h3 className="title-textp">
                                    TensorFlow and Keras Regression Model
                                    <span role="img" aria-label="Heart">
                                        ❤️
                                    </span>
                                </h3>
                            </div>
                            <div className="dl-img5"></div>
                            <div className="backp">
                                <div className="back-contentp">
                                    <div className="layer"></div>
                                    <h4 className="textp">Checkout here...</h4>
                                    <div className="bttn-boxp">
                                        <a
                                            href="https://github.com/arpitsomani8/TensorFlow-And-Keras/tree/master/TensorFlow%20and%20Keras%20Regression%20Model"
                                            className="btnttp"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Github Code
                                        </a>


                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="rotatep">
                        <div className="coffee-cardp">
                            <div className="card-headerp">
                                <h3 className="title-textp">
                                    TensorFlow and Keras Project
                                    <span role="img" aria-label="Heart">
                                        ❤️
                                    </span>
                                </h3>
                            </div>
                            <div className="dl-img6"></div>
                            <div className="backp">
                                <div className="back-contentp">
                                    <div className="layer"></div>
                                    <h4 className="textp">Checkout here...</h4>
                                    <div className="bttn-boxp">
                                        <a
                                            href="https://github.com/arpitsomani8/TensorFlow-And-Keras/tree/master/TensorFlow%20and%20Keras%20Project"
                                            className="btnttp"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Github Code
                                        </a>


                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </Layout>
    )
}

export default Pythonprojectcard
